/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"
import SocialShare from "../components/common/SocialShare"
import { useScroll, useWindowSize } from "../hooks"

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
    const post = data.ghostPost
    const size = useWindowSize()
    const scroll = useScroll()
    const tags = post.tags.map(tag => tag.name)
    const url = data.site.siteMetadata.siteUrl

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <div className="container">
                    <SocialShare
                        isHome={false}
                        url={`${url}/${post.slug}`}
                        title={post.title}
                        tags={tags}
                        round={false}
                        className={`social-share ${
                            size.width >= 1275 ? `big` : `post`
                        } ${scroll ? `scroll-share` : ``}`}
                    />

                    <article className="content">
                        {post.feature_image ? (
                            <figure className="post-feature-image">
                                <img
                                    src={post.feature_image}
                                    alt={post.title}
                                />
                            </figure>
                        ) : null}
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            {/* The main post content */}
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.html }}
                            />
                        </section>
                    </article>
                </div>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
            url: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
              siteUrl
            }
          }
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
